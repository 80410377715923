/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/how-to/querying-data/use-static-query/
 */

import * as React from "react"
import { Slice } from "gatsby"


import "./layout.css"

const Layout = ({ children }) => {
  return (
    <>
      <Slice alias="header"/>
      <div
        style={{
          margin: `0 auto`,
          display: `flex`,
          height: `calc( 100% - 208px)`,
          flexDirection: `column`,
        }}
      >
        <main
          style={{
            margin: `0 auto`,
            flex: `1 0 auto`,
            width: `100%`,
          }}
        >
        {children}</main>
        <Slice alias="footer" />
      </div>
    </>
  )
}

export default Layout
